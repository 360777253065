import $ from "jquery";
import "what-input";

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
//require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
import "./lib/foundation-explicit-pieces";
//import 'slick-carousel/slick/slick';
import "hc-sticky/dist/hc-sticky";
import "owl.carousel2/dist/owl.carousel";
import Splide from "@splidejs/splide";
import slidebars from "exports-loader?slidebars!Slidebars/dist/slidebars.js";
// import datepickerFactory from 'jquery-datepicker';
// import datepickerJAFactory from 'jquery-datepicker/i18n/jquery.ui.datepicker-ja';

// datepickerFactory($);
// datepickerJAFactory($);

$(document).foundation();

$(document).ready(function () {
  var direction =
    $("html").css("direction").toLowerCase() === "rtl" ? "right" : "left";
  var direction_boolean = $("html").css("direction").toLowerCase() === "rtl";

  var directionVal =
    $("html").css("direction").toLowerCase() === "rtl" ? "rtl" : "ltr";

  // var stickyHeader = new hcSticky('#sticky-header', {
  //     stickTo: '#main-wrap',
  //     onStart: function () {
  //         $('.canvas-panel').css('top', $('.main-header-inner').outerHeight());
  //     },
  //     onStop: function () {
  //         $('.canvas-panel').css('top', $('#main-header').outerHeight());
  //     }
  // });

  //sticky header

  //  if ($('#main-header').length > 0) {
  //     var stickyBlock = new hcSticky('#sticky-header', {
  //         stickTo: 'main',
  //         stickyClass: 'hc-sticky',
  //     });
  // }

  // start navpanel
  // Initialize Slidebars
  var controller = new slidebars();
  controller.init();

  $(".toggle-nav-panel").on("click", function (event) {
    // Stop default action and bubbling
    event.stopPropagation();
    event.preventDefault();
    // console.log('click');

    // $('.toggle-nav-panel').find('i').toggleClass('icon-menu icon-close');
    $(".toggle-nav-panel").toggleClass("is-active");
    //console.log("panel");
    // Toggle the Slidebar with id 'id-4'
    controller.toggle("nav-panel");
  });

  // Add close class to canvas container when Slidebar is opening
  $(controller.events).on("opening", function (event) {
    $('[canvas="container"]').addClass("sb-opening");
  });

  // Add close class to canvas container when Slidebar is opening
  $(controller.events).on("opened", function (event) {
    $('[canvas="container"]').addClass("sb-opened");
  });

  // Remove close class from canvas container when Slidebar is closing
  $(controller.events).on("closing", function (event) {
    $('[canvas="container"]').removeClass("sb-opening");
  });

  // Remove close class from canvas container when Slidebar is closing
  $(controller.events).on("closed", function (event) {
    $('[canvas="container"]').removeClass("sb-opened");
  });

  // Close any
  $(document).on("click", "#sb-blocker, .close-panel span", function (event) {
    //.sb-opening
    if (controller.getActiveSlidebar()) {
      event.preventDefault();
      event.stopPropagation();
      controller.close();
    }
    $(".toggle-nav-panel").removeClass("is-active");
    // $('.toggle-nav-panel').find('i').removeClass('icon-close');
    // $('.toggle-nav-panel').find('i').addClass('icon-menu');
  });

  $(document).on("click", ".schedule-meeting", function (event) {
    //.sb-opening
    event.preventDefault();
    if (controller.getActiveSlidebar()) {
      event.preventDefault();
      event.stopPropagation();
      controller.close();
    }
    $(".toggle-nav-panel").removeClass("is-active");
    $("html, body").animate({ scrollTop: $("#sched-meeting").offset().top }, 0);

    // $('.toggle-nav-panel').find('i').removeClass('icon-close');
    // $('.toggle-nav-panel').find('i').addClass('icon-menu');
  });

  // end navpanel

  if ($(".hero-area").length > 0) {
    function fitHeaderHeight() {
      // get window heigth
      var windowHeight = $(window).height() + "px";

      $(".hero-area").css("height", windowHeight);
    }

    fitHeaderHeight();

    $(window).on("resize", function () {
      fitHeaderHeight();
    });
  }

  /** ====================== START: GOOGLE MAPS ============================  **/
  if ($("#map-canvas").length > 0) {
    var map;
    function initialize() {
      var myLatlng = new google.maps.LatLng(
        24.675977578477262,
        46.68236160442656
      );
      var mapOptions = {
        zoom: 20,
        center: myLatlng,
        styles: [
          {
            featureType: "landscape.man_made",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#fafafa",
              },
            ],
          },
          {
            featureType: "landscape.man_made",
            elementType: "geometry.stroke",
            stylers: [
              {
                color: "#c7c7c7",
              },
            ],
          },
          {
            featureType: "landscape.man_made",
            elementType: "labels.text",
            stylers: [
              {
                color: "#626060",
              },
            ],
          },
          {
            featureType: "landscape.natural.landcover",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#ababab",
              },
              {
                weight: 1,
              },
            ],
          },
          {
            featureType: "landscape.natural.landcover",
            elementType: "geometry.stroke",
            stylers: [
              {
                color: "#ababab",
              },
            ],
          },
          {
            featureType: "landscape.natural.terrain",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#dedede",
              },
            ],
          },
          {
            featureType: "poi.attraction",
            elementType: "geometry.fill",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi.business",
            stylers: [
              {
                visibility: "simplified",
              },
            ],
          },
          {
            featureType: "poi.business",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#cfcfcf",
              },
              {
                visibility: "simplified",
              },
            ],
          },
          {
            featureType: "poi.business",
            elementType: "geometry.stroke",
            stylers: [
              {
                color: "#b0b0b0",
              },
            ],
          },
          {
            featureType: "poi.business",
            elementType: "labels.icon",
            stylers: [
              {
                color: "#cfcfcf",
              },
            ],
          },
          {
            featureType: "poi.business",
            elementType: "labels.text",
            stylers: [
              {
                color: "#858585",
              },
            ],
          },
          {
            featureType: "poi.government",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi.medical",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi.park",
            elementType: "labels.text",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi.place_of_worship",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi.school",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi.sports_complex",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
        ],
      };
      map = new google.maps.Map(
        document.getElementById("map-canvas"),
        mapOptions
      );

      var marker = new google.maps.Marker({
        position: myLatlng,
        map: map,
      });
    }

    google.maps.event.addDomListener(window, "load", initialize);
  }

  /** ====================== END: GOOGLE MAPS ============================  **/

  // SPLIDE
  //console.log($('html').css("direction").toLowerCase());
  if ($(".splide_js").length) {
    var splide = new Splide(".splide_js", {
      //type   : 'loop',
      perPage: 3,
      perMove: 1,
      arrows: false,
      pagination: false,
      direction: directionVal,
      gap: 100,
      breakpoints: {
        640: {
          perPage: 1,
        },
      },
    });

    var bar = splide.root.querySelector(".splide_js .my-carousel-progress-bar");

    splide.on("mounted move", function () {
      var end = splide.Components.Controller.getEnd() + 1;
      bar.style.width = String((100 * (splide.index + 1)) / end) + "%";
    });

    splide.mount();
  }

  if ($("#modal-video").length > 0) {
    var video = document.getElementById("video1");
    function stopVideo() {
      video.pause();
      video.currentTime = 0;
    }
    $("#modal-video .close-button").on("click", function () {
      stopVideo();
    });

    $(".btn-play").on("click", function () {
      video.play();
    });
  }

  // BANNER VIDEO
  // Video Card
  if ($("#heroVideo").length) {
    var $id = $("#heroVideo").attr("id");
    var $videoId = $("#heroVideo").data("media-id");
    var heroPlayer = jwplayer($id);
    var vRatio = "16:9";
    var jwplayerApi = "https://cdn.jwplayer.com/v2/media/" + $videoId;

    if (Foundation.MediaQuery.is("medium up") == true) {
      vRatio;
    } else {
      vRatio = "3:4";
    }

    console.log(jwplayerApi, "api");

    fetch(jwplayerApi)
      .then((response) => response.json())
      .then((responseData) => {
        var videoData = responseData.playlist[0];
        var videoUrl = videoData.sources
          .filter((item) => item.type == "video/mp4")
          .slice(-1)[0].file;
        var videoImgUrl = videoData.images.slice(-1)[0].src;

        heroPlayer.setup({
          file: videoUrl,
          allowFullscreen: false,
          responsive: true,
          aspectratio: vRatio,
          autostart: true,
          mute: true,
          controls: false,
        });
      });
  }
});
